import Grid from "@mui/material/Grid";
import MKBox from "../../components/MKBox";
import HeaderThree from "../../layouts/sections/page-sections/page-headers/components/HeaderThree";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import MKSnackbar from "../../components/MKSnackbar";
import React from "react";
import Rating from "../../components/Rating/Rating";
import MKTypography from "../../components/MKTypography";
import Icon from "@mui/material/Icon";
import moment from "moment";
import useReviews from "./useReviews";

const Reviews = () => {

    const {
        snackbarConfig,
        show,
        toggleSnackbar,
        isInitLoading,
        reviews
    } = useReviews();

    const renderReviews = () => {
        return reviews.map(review => <Grid key={review.id} item md={6}>
            <Rating rating={review.score} />
            <MKTypography variant="body2">"{review.content}"</MKTypography>
            <MKBox sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} mt={3}>
                <MKBox>
                    <MKTypography variant="button" fontWeight="bold"
                                  sx={{display: 'block'}}>{review.user.name}</MKTypography>
                    <MKTypography variant="caption" sx={{display: 'block'}}><Icon
                        color="secondary">access_time</Icon> {moment(review.created_at).format('MMM DD, YYYY')}
                    </MKTypography>
                </MKBox>
            </MKBox>
        </Grid>)
    };

    return <MKBox>
        <HeaderThree title="Reviews" subtitle="Check out what some other adventurers have to say"/>
        {
            isInitLoading ?
                <MKBox mt={4} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress color="secondary"/>
                </MKBox> :
                <MKBox mt={4} mb={4} sx={{maxWidth: 1200, marginX: 'auto'}}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={4}>
                                {
                                    renderReviews()
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </MKBox>
        }
        <MKSnackbar
            color={snackbarConfig.color}
            icon={snackbarConfig.icon}
            title={snackbarConfig.title}
            content={snackbarConfig.message}
            dateTime=""
            autoHideDuration={3000}
            open={show}
            close={toggleSnackbar}
        />
    </MKBox>;
};

export default Reviews;
