import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../config";
import {useNavigate} from 'react-router-dom';

const usePreys = () => {

    const navigate = useNavigate();

    const [isInitLoading, setIsInitLoading] = useState(true);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [types, setTypes] = useState([]);
    const [preys, setPreys] = useState([]);

    const [selectedType, setSelectedType] = useState(-1);

    useEffect(() => {
        const init = async () => {
            setIsInitLoading(true);
            await loadTypes();
            await loadPreys();
            setIsInitLoading(false);
        };

        init();
    }, []);

    const loadTypes = async () => {
        try {

            const response = await axios.get(`${API_URL}/types`, {
                params: {
                    is_active: true,
                    sort: 'title',
                    direction: 'asc'
                }
            });

            setTypes([{id: -1, label: 'All Species'}, ...response.data.map(type => ({id: type.id, label: type.title}))]);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the types",
                icon: 'cancel',
                title: 'Types',
                color: 'warning'
            });

            setShow(true);

        }
    };

    const loadPreys = async () => {

        try {


            const response = await axios.get(`${API_URL}/preys`, {
                params: {
                    is_active: true,
                    sort: 'name',
                    direction: 'asc',
                }
            });

            setPreys(response.data);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the target species",
                icon: 'cancel',
                title: 'Target Species',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const handleChange = (value) => {
        setSelectedType(value.id);
    };

    const handleNav = (id) => {
        navigate('/adventures', {state: {preyId: id}})
    };

    return {
        isInitLoading,
        snackbarConfig,
        show,
        toggleSnackbar,
        preys,
        types,
        selectedType,
        handleChange,
        handleNav
    }
};

export default usePreys;
