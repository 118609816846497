import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../config";
import {useParams} from 'react-router-dom';

const useReviews = () => {

    const {id} = useParams();

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [isInitLoading, setIsInitLoading] = useState(true);
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        const init = async () => {
            setIsInitLoading(true);
            await loadReviews();
            setIsInitLoading(false);
        };

        init();
    }, []);

    const loadReviews = async () => {

        try {

            const response = await axios.get(`${API_URL}/reviews`, {
                params: {
                    adventure_id: id,
                    sort: 'created_at',
                    direction: 'desc',
                }
            });

            setReviews(response.data);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the reviews",
                icon: 'cancel',
                title: 'Reviews',
                color: 'warning'
            });

            setShow(true);

        }
    };
    return {
        snackbarConfig,
        show,
        toggleSnackbar,
        isInitLoading,
        reviews
    }
};

export default useReviews;
