import HeaderThree from "../../layouts/sections/page-sections/page-headers/components/HeaderThree";
import MKBox from "../../components/MKBox";
import React from "react";
import Grid from "@mui/material/Grid";
import AdventureCard from "../../components/AdventureCard/AdventureCard";
import CircularProgress from "@mui/material/CircularProgress";
import {APP_URL} from "../../config";
import MKSnackbar from "../../components/MKSnackbar";
import {Link} from "react-router-dom";
import useWishlist from "./useWishlist";

const Wishlist = () => {

    const {
        isInitLoading,
        isLoading,
        snackbarConfig,
        show,
        toggleSnackbar,
        adventures,
    } = useWishlist();

    const renderWishlist = () => {
        return adventures.map(adventure => <Grid key={adventure.id} item md={4} sx={{marginTop: 2}}>
            <Link to={`/adventure-details/${adventure.id}`}>
                <AdventureCard
                    image={adventure.images.length > 0 ? `${APP_URL}/storage/photos/${adventure.images[0].name}` : 'https://via.placeholder.com/150'}
                    title={adventure.name}
                    description={adventure.short_description}
                    meta={[`${adventure.city.name}, ${adventure.city.state.name}`, `${adventure.preys.length} Preys`]}
                    action={{
                        type: "internal",
                        route: "/somewhere",
                        color: "secondary",
                        label: "View More",
                    }}
                />
            </Link>
        </Grid>)
    };

    return <MKBox>
        <HeaderThree brandWhite={true} title="Wishlist" subtitle="Here are your saved trips"/>
        {
            isInitLoading ?
                <MKBox mt={4} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress color="secondary"/>
                </MKBox> :
                <MKBox mt={8} mb={8} sx={{maxWidth: 1200, marginX: 'auto'}}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12}>
                            {
                                isLoading ?
                                    <MKBox mt={4}
                                           sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <CircularProgress color="secondary"/>
                                    </MKBox> :
                                    <Grid container spacing={4}>
                                        {
                                            renderWishlist()
                                        }
                                    </Grid>
                            }
                        </Grid>
                    </Grid>
                </MKBox>
        }
        <MKSnackbar
            color={snackbarConfig.color}
            icon={snackbarConfig.icon}
            title={snackbarConfig.title}
            content={snackbarConfig.message}
            dateTime=""
            autoHideDuration={3000}
            open={show}
            close={toggleSnackbar}
        />
    </MKBox>
};

export default Wishlist;
