import MKBox from "../MKBox";
import Icon from "@mui/material/Icon";
import MKTypography from "../MKTypography";
import React from "react";

const IconBox = ({icon, title, content, iconPosition, onClick = null}) => {
    return <MKBox onClick={onClick ? onClick : null}
        sx={{
            display: 'flex',
            flexDirection: iconPosition === 'top' ? 'column' : 'row',
            justifyContent: 'flex-start',
            alignItems: iconPosition === 'top' ? 'flex-start' : 'center',
            cursor: onClick !== null ? 'pointer' : 'default'
        }}>
        <MKBox bgColor="primary" variant="gradient" px={2} py={1} mb={iconPosition === 'top' ? 2 : 0}
               sx={{borderRadius: 3}}>
            <Icon sx={{color: '#FFFFFF'}}>{icon}</Icon>
        </MKBox>
        <MKBox ml={iconPosition === 'left' ? 2 : 0}>
            {
                title &&
                <MKTypography variant="h6">{title}</MKTypography>
            }
            {
                content &&
                <MKTypography variant="body2" color="dark" fontWeight="light">{content}</MKTypography>
            }
        </MKBox>
    </MKBox>
};

export default IconBox;
