import HeaderThree from "../../layouts/sections/page-sections/page-headers/components/HeaderThree";
import MKBox from "../../components/MKBox";
import React from "react";
import Grid from "@mui/material/Grid";
import AdventureCard from "../../components/AdventureCard/AdventureCard";
import CircularProgress from "@mui/material/CircularProgress";
import {APP_URL} from "../../config";
import MKSnackbar from "../../components/MKSnackbar";
import {Link} from "react-router-dom";
import usePreys from "./usePreys";
import IconBox from "../../components/IconBox/IconBox";
import MDAutocomplete from "../../components/MDAutocomplete";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import MKInput from "../../components/MKInput";

const Preys = () => {

    const {
        isInitLoading,
        isLoading,
        snackbarConfig,
        show,
        toggleSnackbar,
        preys,
        types,
        selectedType,
        handleChange,
        handleNav
    } = usePreys();

    const renderPreys = () => {

        if (selectedType > 0) {
            return preys.filter(prey => prey.type_id === selectedType).map(prey => <Grid item md={4} xs={12}>
                    <IconBox onClick={() => handleNav(prey.id)} iconPosition="top" icon={prey.type.image} title={prey.name}
                             content={prey.description}/>
            </Grid>)
        }

        return preys.map(prey => <Grid item md={4} xs={12}>

                <IconBox onClick={() => handleNav(prey.id)} iconPosition="top" icon={prey.icon} title={prey.name}
                         content={prey.description}/>
        </Grid>)
    };

    return <MKBox>
        <HeaderThree brandWhite={true} title="Target Species" subtitle="Search for the game you want"/>
        {
            isInitLoading ?
                <MKBox mt={4} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress color="secondary"/>
                </MKBox> :
                <MKBox mt={8} mb={8} sx={{maxWidth: 1200, marginX: 'auto'}}>
                    <MKBox mb={6}>
                        <Autocomplete
                            fullWidth
                            options={types}
                            value={selectedType}
                            getOptionLabel={(option) => {

                                const optionType = types.find(type => type.id == option);

                                if (optionType)
                                    return optionType.label;
                                else
                                    return option.label;
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value}
                            onChange={(event, value) => handleChange(value)}
                            renderInput={(params) => <MKInput {...params} variant="standard" label="Species Type"/>}
                        />
                    </MKBox>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={4}>
                                {
                                    renderPreys()
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </MKBox>
        }
        <MKSnackbar
            color={snackbarConfig.color}
            icon={snackbarConfig.icon}
            title={snackbarConfig.title}
            content={snackbarConfig.message}
            dateTime=""
            autoHideDuration={3000}
            open={show}
            close={toggleSnackbar}
        />
    </MKBox>
};

export default Preys;
