/* eslint-disable no-param-reassign */
/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes

import brand from "assets/images/logo.png";
import {loggedRoutes, routes} from "../../../../../../routes";
import {useSelector} from "react-redux";

function NavbarLight() {

    const authState = useSelector((state) => state.authReducer);

  return (
    <MKBox py={0.25}>
      <DefaultNavbar
        routes={authState.isLoggedIn ? loggedRoutes : routes}
        transparent
        relative
        brand={brand}
      />
    </MKBox>
  );
}

export default NavbarLight;
