/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "filter-form",
  formField: {
    preys: {
      name: "preys",
      label: "Target Species",
      type: "array",
    },
    min_price: {
      name: "min_price",
      label: "Min Price",
      type: "text",
      errorMsg: "Must be a number.",
    },
    max_price: {
      name: "max_price",
      label: "Max Price",
      type: "text",
      errorMsg: "Must be a number.",
    },
    radius: {
      name: "radius",
      label: "Radius",
      type: "slider",
      errorMsg: "Must be a number.",
    }
  },
};

export default form;
