/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import FormField from "../../../../../components/FormField";


function RecoverPasswordForm({formData}) {

    const {formField, values, errors, touched} = formData;
    const {email} = formField;
    const {
        email: emailV,
    } = values;

    return (
        <MKBox>
            <MKBox mb={3}>
                <FormField
                    type={email.type}
                    label={email.label}
                    name={email.name}
                    value={emailV}
                    placeholder={email.placeholder}
                    error={errors.email && touched.email}
                    success={emailV.length > 0 && !errors.email}
                />
            </MKBox>
        </MKBox>
    );
}

export default RecoverPasswordForm;
