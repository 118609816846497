/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Dashboard 2 PRO React components
import MKInput from "components/MKInput";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import Slider from "@mui/material/Slider";

function MKSlider({input, field, form, ...rest}) {

    return (
        <Slider
            {...field}
            {...rest}
            valueLabelDisplay="auto"
            onChange={(event, value) => {
                form.setFieldValue(field.name, value);
            }}
        />
    );
}

export default MKSlider;
