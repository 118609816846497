import MKBox from "../../components/MKBox";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import MKTypography from "../../components/MKTypography";
import Icon from "@mui/material/Icon";
import {Form, Formik} from "formik";
import checkoutInitialValues from "./schemas/checkout/initialValues";
import checkoutForm from "./schemas/checkout/form";
import checkoutValidations from "./schemas/checkout/validations";
import MKButton from "../../components/MKButton";
import BillingForm from "./components/BillingForm/BillingForm";
import PaymentForm from "./components/PaymentForm/PaymentForm";
import {useLocation} from 'react-router-dom';
import moment from "moment";
import {loadStripe} from '@stripe/stripe-js';
import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {API_URL, APP_URL, BASE_URL, STRIPE_KEY} from "../../config";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import MKSnackbar from "../../components/MKSnackbar";

const stripePromise = loadStripe(STRIPE_KEY);

const CheckItem = ({text}) => {
    return <MKBox sx={{display: 'flex', alignItems: 'center'}}>
        <Icon color="secondary">check_circle_outline</Icon>
        <MKTypography sx={{marginLeft: 1}} variant="body2" fontWeight="light">{text}</MKTypography>
    </MKBox>
};

const CheckoutForm = ({paymentIntentId}) => {

    const {formId, formField} = checkoutForm;
    const currentValidation = checkoutValidations;

    const {state: {cartItems, adventureId, due_at}} = useLocation();

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const stripe = useStripe();
    const elements = useElements();

    const updatePaymentIntent = async (adventureId, formValues, cartItems, dueAt) => {

        try {
            await axios.post(`${API_URL}/reservations/update_payment_intent`, {
                adventure_id: adventureId,
                due_at: dueAt,
                cart_items: cartItems,
                payment_intent_id: paymentIntentId,
                ...formValues
            });

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong updating the checkout details.",
                icon: 'cancel',
                title: 'Checkout',
                color: 'warning'
            });

            setShow(true);
        }
    };

    const getCartTotal = () => {
        return cartItems.reduce((sum, item) => {
            return sum + (item.unit_price * item.amount);
        }, 0)
    };

    const handleSubmit = async (values, actions) => {

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        actions.setSubmitting(true);

        try {
            await updatePaymentIntent(adventureId, values, cartItems, due_at);
        } catch (error) {
            return;
        }

        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${BASE_URL}/thank-you`,
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setSnackbarConfig({
                message: error.message,
                icon: 'cancel',
                title: 'Checkout',
                color: 'warning'
            });

            setShow(true);
        } else {
            setSnackbarConfig({
                message: 'An unexpected error occurred.',
                icon: 'cancel',
                title: 'Checkout',
                color: 'warning'
            });

            setShow(true);
        }

        actions.setSubmitting(false);
    };

    return <MKBox>
        <Formik
            initialValues={checkoutInitialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
        >
            {({values, errors, touched, isSubmitting}) => (
                <Form id={formId} autoComplete="off">

                    <MKTypography variant="h3">Billing Info</MKTypography>
                    <BillingForm formData={{values, touched, formField, errors}}/>
                    <MKBox mt={3}/>
                    <MKTypography variant="h3">Payment Info</MKTypography>
                    <MKBox mt={3} />
                    <PaymentElement/>
                    <MKBox mt={3}/>
                    <MKButton variant="gradient" color="secondary" fullWidth
                              disabled={isSubmitting} type="submit">Pay Booking Fee
                        (${(getCartTotal() * 0.07).toFixed(2)} USD)</MKButton>
                </Form>
            )}
        </Formik>
        <MKSnackbar
            color={snackbarConfig.color}
            icon={snackbarConfig.icon}
            title={snackbarConfig.title}
            content={snackbarConfig.message}
            dateTime=""
            autoHideDuration={3000}
            open={show}
            close={toggleSnackbar}
        />
    </MKBox>;
};

const Checkout = () => {

    const [isInitLoading, setIsInitLoading] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const [paymentIntentId, setPaymentIntentId] = useState('');

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const {state: {cartItems, adventureId, due_at}} = useLocation();

    const getCartTotal = () => {
        return cartItems.reduce((sum, item) => {
            return sum + (item.unit_price * item.amount);
        }, 0)
    };

    useEffect(() => {
        const init = async () => {
            setIsInitLoading(true);
            await createPaymentIntent();
            setIsInitLoading(false);
        };

        init();
    }, []);

    const createPaymentIntent = async () => {

        try {
            const response = await axios.post(`${API_URL}/reservations/create_payment_intent`, {
                booking_fee: getCartTotal() * 0.07
            });

            setClientSecret(response.data.secret);
            setPaymentIntentId(response.data.payment_intent_id);


        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong initiating the checkout process.",
                icon: 'cancel',
                title: 'Checkout',
                color: 'warning'
            });

            setShow(true);
        }
    };

    const renderCartItems = () => {

        return cartItems.map((item) => (
            <MKBox key={item.id} p={2} bgColor="white"
                   sx={{
                       display: 'flex',
                       flexDirection: 'row',
                       alignItems: 'center',
                       justifyContent: 'space-between',
                       borderStyle: 'solid',
                       borderWidth: 1,
                       borderColor: '#CECECE'
                   }}>
                <MKTypography mr={2} variant="h6" fontWeight="light">{item.amount}</MKTypography>
                <MKBox style={{flex: 1}}>
                    <MKTypography variant="h6">{item.name}</MKTypography>
                    <MKTypography variant="body2">{moment(due_at).format('MMM DD, YYYY')}</MKTypography>
                </MKBox>
                <MKTypography ml={2} variant="body2" fontWeight="bold"
                              color="secondary">${(item.unit_price * item.amount).toFixed(2)} USD</MKTypography>
            </MKBox>
        ))
    };

    return <MKBox sx={{
        background: {
            md: "linear-gradient(90deg, rgba(243,239,245,1) 50%, rgba(255,255,255,1) 50%)"
        },
        minHeight: '100vh'
    }}>
        <MKBox sx={{maxWidth: 1200}} mx={"auto"}>
            <Grid container spacing={3}>
                <Grid item md={6}>
                    <MKBox py={8} px={4}>
                        <MKTypography variant="subtitle2">Outdoor Adventures</MKTypography>
                        <MKTypography variant="h1">Checkout</MKTypography>
                        <MKBox mt={3}/>
                        <MKTypography variant="body2">Almost done booking your adventure. Enter your payment details to
                            finish the checkout process.</MKTypography>
                        <MKBox mt={4}/>
                        {
                            isInitLoading ?
                                <MKBox sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <CircularProgress color="secondary"/>
                                </MKBox> :
                                <Elements stripe={stripePromise} options={{clientSecret}}>
                                    <CheckoutForm paymentIntentId={paymentIntentId}/>
                                </Elements>

                        }
                    </MKBox>
                </Grid>
                <Grid item md={6}>
                    <MKBox py={8} px={5}>
                        <MKBox mt={3}/>
                        <MKTypography variant="h1">Your Booking</MKTypography>
                        <MKBox mt={3}/>
                        <MKTypography variant="body2">Review your order to make sure it's correct.</MKTypography>
                        <MKBox mt={3}>
                            <MKTypography variant="h3">Order Summary</MKTypography>
                            <MKBox mt={2}>
                                {
                                    renderCartItems()
                                }
                            </MKBox>
                        </MKBox>
                        <MKBox mt={3}>
                            <MKTypography variant="h3">Payment Summary</MKTypography>
                            <MKBox mt={2}>
                                <CheckItem text={`Pay your booking fee of $${(getCartTotal() * 0.07).toFixed(2)} today.`}/>
                                <CheckItem
                                    text={`Pay the remaining $${(getCartTotal() * 0.93).toFixed(2)} upon arriving to your adventure.`}/>
                            </MKBox>
                            <MKBox mt={2} sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', borderWidth: 1, borderStyle: 'solid', borderColor: '#CECECE'}} p={1}>
                                <Icon color="secondary">volunteer_activism_outlined</Icon>
                                <MKTypography sx={{marginLeft: 2}} variant="body2">3% of your booking fee will be donated to a profit wildlife organization</MKTypography>
                            </MKBox>
                        </MKBox>
                    </MKBox>
                </Grid>
            </Grid>
        </MKBox>
        <MKSnackbar
            color={snackbarConfig.color}
            icon={snackbarConfig.icon}
            title={snackbarConfig.title}
            content={snackbarConfig.message}
            dateTime=""
            autoHideDuration={3000}
            open={show}
            close={toggleSnackbar}
        />
    </MKBox>
};

export default Checkout;
