/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "checkout-form",
  formField: {
    name: {
      name: 'name',
      label: 'Name',
      type: 'text',
      errorMsg: 'Name is required.'
    },
    email: {
      name: 'email',
      label: 'Email',
      type: 'text',
      errorMsg: 'Email is required.',
      invalidMsg: 'Not a valid email',
    },
    billing_address: {
      name: 'billing_address',
      label: 'Billing Address',
      type: 'text',
      errorMsg: 'Billing address is required.'
    },
    city: {
      name: 'city',
      label: 'City',
      type: 'text',
      errorMsg: 'City is required.'
    },
    state: {
      name: 'state',
      label: 'State',
      type: 'text',
      errorMsg: 'State is required.'
    }
  },
};

export default form;
