
/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import React from "react";
import MKBox from "../../../../components/MKBox";
import FormField from "../../../../components/FormField";
// Material Dashboard 2 PRO React components


function PaymentForm({formData}) {

    const {formField, values, errors, touched} = formData;

    const {card_number, card_cvv, card_exp} = formField;
    const {
        card_number: cardNumberV,
        card_cvv: cardCVVV,
        card_exp: cardExpV,
    } = values;

    return (
        <MKBox mt={2}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormField
                        type={card_number.type}
                        label={card_number.label}
                        name={card_number.name}
                        value={cardNumberV}
                        error={errors.card_number && touched.card_number}
                        success={cardNumberV && cardNumberV.length > 0 && !errors.card_number}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormField
                        type={card_exp.type}
                        label={card_exp.label}
                        name={card_exp.name}
                        value={cardExpV}
                        error={errors.card_exp && touched.card_exp}
                        success={cardExpV && cardExpV.length > 0 && !errors.card_exp}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormField
                        type={card_cvv.type}
                        label={card_cvv.label}
                        name={card_cvv.name}
                        value={cardCVVV}
                        error={errors.card_cvv && touched.card_cvv}
                        success={cardCVVV && cardCVVV.length > 0 && !errors.card_cvv}
                    />
                </Grid>
            </Grid>
        </MKBox>
    );
}

export default PaymentForm;
