import Icon from "@mui/material/Icon";
import React from "react";

const Rating = ({rating, color = "secondary"}) => {

    let stars = [];

    for (let i = 1; i <= 5; i++) {
        if (i <= rating) {
            stars.push(<Icon color={color} sx={{color: color}} key={i}>star</Icon>);
        } else if (i - rating === 0.5) {
            stars.push(<Icon color={color} sx={{color: color}} key={i}>star_half</Icon>);
        } else {
            stars.push(<Icon color={color} sx={{color: color}} key={i}>star_border</Icon>);
        }
    }
    return <div>{stars}</div>;
};

export default Rating;
