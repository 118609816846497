
/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import React from "react";
import moment from "moment";
import MKBox from "../../../components/MKBox";
import FormField from "../../../components/FormField";
// Material Dashboard 2 PRO React components


function ReviewForm({formData}) {

    const {formField, values, errors, touched} = formData;

    const {score, content} = formField;
    const {
        score: scoreV,
        content: contentV,
    } = values;

    return (
        <MKBox>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <FormField
                        type={score.type}
                        label={score.label}
                        name={score.name}
                        value={scoreV}
                        options={[{id: -1, label: 'Choose an option'}, {id: 1, label: '0 stars'}, {id: 2, label: '1 stars'}, {id: 3, label: '2 stars'}, {id: 4, label: '3 stars'}, {id: 5, label: '4 stars'}, {id: 6, label: '5 stars'}]}
                        error={errors.score && touched.score}
                        success={scoreV && scoreV.length > 0 && !errors.score}
                    />
                </Grid>
                <Grid item xs={12} md={9}>
                    <FormField
                        type={content.type}
                        label={content.label}
                        name={content.name}
                        value={contentV}
                        error={errors.content && touched.content}
                        success={contentV && contentV.length > 0 && !errors.content}
                    />
                </Grid>
            </Grid>
        </MKBox>
    );
}

export default ReviewForm;
