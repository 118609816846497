
/**
 =========================================================
 * Material Kit 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { Fragment } from "react";

// react-router components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

function AdventureCard({ image, title, description, meta, action }) {
    return (
        <Card>
            <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
                <MKBox
                    component="img"
                    src={image}
                    alt={title}
                    borderRadius="lg"
                    shadow="md"
                    width="100%"
                    height={250}
                    sx={{objectFit: 'cover'}}
                    position="relative"
                    zIndex={1}
                />
                <MKBox
                    borderRadius="lg"
                    shadow="md"
                    width="100%"
                    height="100%"
                    position="absolute"
                    left={0}
                    top={0}
                    sx={{
                        backgroundImage: `url(${image})`,
                        transform: "scale(0.94)",
                        filter: "blur(12px)",
                        backgroundSize: "cover",
                    }}
                />
            </MKBox>
            <MKBox p={3} mt={-2}>
                {meta.length > 0 && (
                    <MKTypography
                        display="block"
                        variant="button"
                        color="primary"
                        fontWeight="regular"
                        mb={0.75}
                    >
                        {meta.map((category) => (
                            <Fragment key={category}>{category}&nbsp;&bull;&nbsp;</Fragment>
                        ))}
                    </MKTypography>
                )}
                <MKTypography display="inline" variant="h5" color="dark" fontWeight="bold">
                    {title}
                </MKTypography>
                <MKBox mt={1} mb={3}>
                    <MKTypography variant="body2" component="p" color="text">
                        {description}
                    </MKTypography>
                </MKBox>
                {action.type === "external" ? (
                    <MKButton
                        component={MuiLink}
                        href={action.route}
                        target="_blank"
                        rel="noreferrer"
                        variant="outlined"
                        size="small"
                        color={action.color ? action.color : "dark"}
                    >
                        {action.label}
                    </MKButton>
                ) : (
                    <MKButton
                        component={Link}
                        to={action.route}
                        variant="outlined"
                        size="small"
                        color={action.color ? action.color : "dark"}
                    >
                        {action.label}
                    </MKButton>
                )}
            </MKBox>
        </Card>
    );
}

export default AdventureCard;
