
export const ENVIRONMENT = 'PRODUCTION';

export const MAPS_API_KEY = "AIzaSyBO9lgtvfo8Wg9Czx6diTbWQwog-LPQOS8";
export const STRIPE_KEY = ENVIRONMENT === 'LOCAL' ? "pk_test_51MWRlPCLHy9x2lG8JQM3wTJjoUezECDoZbeB0PQkvdv1bhQs44qONldHqUi9gBi0T66cf3iTWa0qsqDUkXLApntD00nv08HImA" : 'pk_test_51MWRlPCLHy9x2lG8JQM3wTJjoUezECDoZbeB0PQkvdv1bhQs44qONldHqUi9gBi0T66cf3iTWa0qsqDUkXLApntD00nv08HImA'

const getAppUrl = () => {
    if(ENVIRONMENT === 'LOCAL')
        return ('http://localhost:8000');

    if(ENVIRONMENT === 'STAGING')
        return 'https://api.teku.astwarehosting.com';

    if(ENVIRONMENT === 'PRODUCTION')
        return 'https://api.tekuoutdoors.com';
};

export const BASE_URL = ENVIRONMENT === 'LOCAL' ? 'http://localhost:3001' : 'https://app.tekuoutdoors.com';
export const APP_URL = getAppUrl();
export const API_URL = APP_URL + '/api';

export const PUSHER_ID = 'a2774cf397b9c638463a';
