import MKBox from "../../components/MKBox";
import React from "react";
import NavbarLight from "../../layouts/sections/navigation/navbars/components/NavbarLight";
import MKTypography from "../../components/MKTypography";
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";
import MKButton from "../../components/MKButton";
import Icon from "@mui/material/Icon";

const ThankYou = () => {

    const navigate = useNavigate();

    return <MKBox>
        <NavbarLight/>
        <MKBox>
            <MKBox sx={{maxWidth: 1000}} mt={4} mb={12} mx="auto">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <MKBox sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <Icon color="secondary" fontSize="large">check_circle</Icon>
                            <MKTypography variant="h1" sx={{textAlign: 'center'}}>Thank You!</MKTypography>
                            <MKBox mt={2}/>
                            <MKTypography variant="body2" sx={{textAlign: 'center'}}>You're now ready for your
                                adventure. To make sure your experience is as smoooth as possible, chat with your guide
                                to learn more about the adventure.</MKTypography>
                            <MKButton sx={{marginTop: 4}} variant="gradient" color="secondary" onClick={() => {
                                navigate('/chat');
                            }}>Go To Chat</MKButton>
                        </MKBox>
                    </Grid>
                </Grid>
            </MKBox>
        </MKBox>
    </MKBox>
};

export default ThankYou;
