import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";
import MKButton from "../../../../components/MKButton";
import Icon from "@mui/material/Icon";
import React from "react";

const CartItem = ({id, title, description, price, amount, onAdd, onRemove}) => {
    return <MKBox mb={6}>
        <MKTypography variant="h5">{title}</MKTypography>
        <MKTypography variant="h6" color="secondary" fontWeight="medium">${price}</MKTypography>
        <MKTypography variant="body2" mt={1} sx={{fontSize: 16}}>{description}</MKTypography>
        <MKBox mt={1} display="flex" flexDirection="row" alignItems="center">
            <MKButton disabled={amount === 0} color="secondary" variant="contained" iconOnly
                      onClick={() => onRemove(id)} style={{marginRight: 8}}><Icon>remove</Icon></MKButton>
            <MKTypography>{amount}</MKTypography>
            <MKButton color="secondary" variant="contained" iconOnly onClick={() => onAdd(id, title, price)}
                      style={{marginLeft: 8}}><Icon>add</Icon></MKButton>
        </MKBox>
    </MKBox>
};

export default CartItem;
