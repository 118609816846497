/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import React, {useState} from "react";
import MKBox from "../../../../components/MKBox";
import FormField from "../../../../components/FormField";
import Card from "@mui/material/Card";
import MKTypography from "../../../../components/MKTypography";
import MKButton from "../../../../components/MKButton";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse/Collapse";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import MKInput from "../../../../components/MKInput";
import {Field, FieldArray} from "formik";
import Slider from "@mui/material/Slider";

// Material Dashboard 2 PRO React components


const TypeCollapse = ({ id, title, values, preyData, arrayHelpers}) => {

    const [isCollapsed, setIsCollapsed] = useState(true);

    return <MKBox>
        <MKBox style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        }}>
            <MKTypography variant="h6"
                          sx={{display: 'inline-block'}}>{title}</MKTypography>
            <MKButton iconOnly color="dark" variant="text" size="large"
                      onClick={() => setIsCollapsed(!isCollapsed)}><Icon>{isCollapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</Icon></MKButton>
        </MKBox>
        <Collapse in={!isCollapsed}>
        {
            preyData.filter(prey => prey.type_id === id).map(prey => (
                <FormField
                    type="checkbox"
                    label={prey.name}
                    name={prey.name}
                    value={prey.id}
                    checked={values.preys.includes(prey.id)}
                    onChange={e => {
                        if (e.target.checked) {
                            arrayHelpers.push(prey.id);
                        } else {
                            const idx = values.preys.indexOf(prey.id);
                            arrayHelpers.remove(idx);
                        }
                    }}
                />
            ))
        }
        </Collapse>
    </MKBox>;
};

function FilterForm({formData, typeData, preyData}) {

    const {formField, values, errors, touched} = formData;

    const [isCollapsed, setIsCollapsed] = useState(true);

    const {preys, min_price, max_price, radius} = formField;
    const {
        preys: preysV,
        min_price: minPriceV,
        max_price: maxPriceV,
        radius: radiusV
    } = values;

    const renderTypes = (arrayHelpers) => {

        return typeData.map(type => (
            <TypeCollapse id={type.id} title={type.title} values={values} arrayHelpers={arrayHelpers} preyData={preyData.filter(prey => prey.type_id === type.id)}/>
        ));
    };

    return (
        <MKBox>
            <Card raised>
                <MKBox p={3} pr={1}>
                    <MKBox style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <MKTypography variant="h5" sx={{display: 'inline-block'}}>Types</MKTypography>
                    </MKBox>
                    <FieldArray
                        name={preys.name}
                        render={arrayHelpers => (
                            <MKBox mt={2} pl={1} sx={{maxHeight: 250, overflowY: 'scroll'}}>
                                {
                                    renderTypes(arrayHelpers)
                                }
                            </MKBox>
                        )}
                    />
                </MKBox>
            </Card>
            <MKBox mt={2} mb={2}>
                <Card raised>
                    <MKBox p={3}>
                        <MKBox mb={2}>
                            <MKTypography variant="h5">Price Range</MKTypography>
                        </MKBox>
                        <MKBox mb={2}>
                            <FormField
                                type={min_price.type}
                                label={min_price.label}
                                name={min_price.name}
                                value={minPriceV}
                                error={errors.min_price && touched.min_price}
                                success={minPriceV && minPriceV.length > 0 && !errors.min_price}
                            />
                        </MKBox>
                        <FormField
                            type={max_price.type}
                            label={max_price.label}
                            name={max_price.name}
                            value={maxPriceV}
                            error={errors.max_price && touched.max_price}
                            success={maxPriceV && maxPriceV.length > 0 && !errors.max_price}
                        />
                    </MKBox>
                </Card>
            </MKBox>
            <MKBox mt={2} mb={2}>
                <Card raised>
                    <MKBox p={3}>
                        <MKBox mb={2}>
                            <MKTypography variant="h5">Distance Radius</MKTypography>
                        </MKBox>
                        <FormField
                            type={radius.type}
                            label={radius.label}
                            name={radius.name}
                            value={radiusV}
                            min={10}
                            max={500}
                            marks={[
                                {
                                    value: 10,
                                    label: '10mi',
                                },
                                {
                                    value: 500,
                                    label: '500mi',
                                }
                            ]}
                            error={errors.radius && touched.radius}
                            success={radiusV && radiusV.length > 0 && !errors.radius}
                        />
                    </MKBox>
                </Card>
            </MKBox>
        </MKBox>
    );
}

export default FilterForm;
