/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import {Provider} from "react-redux";
import axios from "axios";
import rootReducer from "./store/reducers/rootReducer";
import {applyMiddleware, createStore} from "redux";
import reduxThunk from "redux-thunk";

axios.defaults.withCredentials = true;

// Crear store de Redux
const store = createStore(
    rootReducer,
    {},
    applyMiddleware(reduxThunk)
);

ReactDOM.render(
    <BrowserRouter>
            <Provider store={ store }>
                <App />
            </Provider>
    </BrowserRouter>,
    document.getElementById("root")
);
