
/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import React from "react";
import MKBox from "../../../../components/MKBox";
import FormField from "../../../../components/FormField";
import moment from "moment";
// Material Dashboard 2 PRO React components


function SearchForm({formData, cities}) {

    const {formField, values, errors, touched} = formData;

    const {city_id} = formField;
    const {
        city_id: cityIdV
    } = values;

    return (
        <MKBox>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormField
                        type={city_id.type}
                        label={city_id.label}
                        name={city_id.name}
                        value={cityIdV}
                        options={cities}
                        error={errors.city_id && touched.city_id}
                        success={cityIdV && cityIdV.length > 0 && !errors.city_id}
                    />
                </Grid>
                {/*<Grid item xs={12} md={6}>*/}
                {/*    <FormField*/}
                {/*        type={due_at.type}*/}
                {/*        label={due_at.label}*/}
                {/*        name={due_at.name}*/}
                {/*        value={dueAtV}*/}
                {/*        options={{enableTime: false, minDate: moment().add(3, 'days').format('YYYY-MM-DD')}}*/}
                {/*        error={errors.due_at && touched.due_at}*/}
                {/*        success={dueAtV && dueAtV.length > 0 && !errors.due_at}*/}
                {/*    />*/}
                {/*</Grid>*/}
            </Grid>
        </MKBox>
    );
}

export default SearchForm;
