import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../config";
import {useLocation} from 'react-router-dom';

const useAdventure = () => {

    const {state} = useLocation();

    const [isInitLoading, setIsInitLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [cities, setCities] = useState([]);
    const [types, setTypes] = useState([]);
    const [preys, setPreys] = useState([]);
    const [adventures, setAdventures] = useState([]);

    const [filters, setFilters] = useState(state !== null ? {preys: [state.preyId]} : null);
    const [page, setPage] = useState(1);

    useEffect(() => {
        const init = async () => {
            setIsInitLoading(true);
            await loadCities();
            await loadTypes();
            await loadPreys();
            await loadAdventures();
            setIsInitLoading(false);
        };

        init();
    }, []);

    useEffect(() => {

        loadAdventures();

    }, [filters, page]);

    const loadCities = async () => {
        try {

            const response = await axios.get(`${API_URL}/cities`, {
                params: {
                    is_active: true,
                    sort: 'name',
                    direction: 'asc'
                }
            });

            setCities([{id: -1, label: 'Choose a destination'}, ...response.data.map(city => ({
                id: city.id,
                label: `${city.name}, ${city.state.name}`
            }))]);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the cities",
                icon: 'cancel',
                title: 'Cities',
                color: 'warning'
            });

            setShow(true);

        }
    };

    const loadTypes = async () => {
        try {

            const response = await axios.get(`${API_URL}/types`, {
                params: {
                    is_active: true,
                    sort: 'title',
                    direction: 'asc'
                }
            });

            setTypes(response.data);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the types",
                icon: 'cancel',
                title: 'Types',
                color: 'warning'
            });

            setShow(true);

        }
    };

    const loadPreys = async () => {
        try {

            const response = await axios.get(`${API_URL}/preys`, {
                params: {
                    is_active: true,
                    sort: 'name',
                    direction: 'asc'
                }
            });

            setPreys(response.data.map(prey => ({id: prey.id, name: prey.name, type_id: prey.type_id})));

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the target species",
                icon: 'cancel',
                title: 'Target Species',
                color: 'warning'
            });

            setShow(true);

        }
    };

    const loadAdventures = async () => {

        setIsLoading(true);

        try {

            console.log('About to load');
            if (filters && filters.city_id === -1){
                delete filters.city_id;
            }

            const response = await axios.get(`${API_URL}/adventures`, {
                params: {
                    is_active: true,
                    sort: 'random',
                    per_page: 10,
                    page,
                    direction: 'asc',
                    ...filters
                }
            });

            setAdventures(response.data.data);

        } catch (error) {

            console.log(error);

            setSnackbarConfig({
                message: "Something went wrong loading the adventures",
                icon: 'cancel',
                title: 'Adventures',
                color: 'warning'
            });

            setShow(true);

        }

        setIsLoading(false);
    };

    const handleSubmit = async (values, actions) => {

        try {

            setFilters({...filters, ...values});

        } catch (error) {

        }

        actions.setSubmitting(false);

    };

    return {
        isInitLoading,
        isLoading,
        snackbarConfig,
        show,
        toggleSnackbar,
        cities,
        preys,
        types,
        adventures,
        page,
        setPage,
        handleSubmit,
        state,
        filters
    }
};

export default useAdventure;
