
/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import React from "react";
import MKBox from "../../../../components/MKBox";
import FormField from "../../../../components/FormField";
// Material Dashboard 2 PRO React components


function BillingForm({formData}) {

    const {formField, values, errors, touched} = formData;

    const {name, email, billing_address, city, state} = formField;
    const {
        name: nameV,
        email: emailV,
        billing_address: billingAddressV,
        city: cityV,
        state: stateV
    } = values;

    return (
        <MKBox mt={2}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormField
                        type={name.type}
                        label={name.label}
                        name={name.name}
                        value={nameV}
                        error={errors.name && touched.name}
                        success={nameV && nameV.length > 0 && !errors.name}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormField
                        type={email.type}
                        label={email.label}
                        name={email.name}
                        value={emailV}
                        error={errors.email && touched.email}
                        success={emailV && emailV.length > 0 && !errors.email}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormField
                        type={billing_address.type}
                        label={billing_address.label}
                        name={billing_address.name}
                        value={billingAddressV}
                        error={errors.billing_address && touched.billing_address}
                        success={billingAddressV && billingAddressV.length > 0 && !errors.billing_address}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormField
                        type={city.type}
                        label={city.label}
                        name={city.name}
                        value={cityV}
                        error={errors.city && touched.city}
                        success={cityV && cityV.length > 0 && !errors.city}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormField
                        type={state.type}
                        label={state.label}
                        name={state.name}
                        value={stateV}
                        error={errors.state && touched.state}
                        success={stateV && stateV.length > 0 && !errors.state}
                    />
                </Grid>
            </Grid>
        </MKBox>
    );
}

export default BillingForm;
