import HeaderThree from "../../layouts/sections/page-sections/page-headers/components/HeaderThree";
import MKBox from "../../components/MKBox";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKTypography from "../../components/MKTypography";
import moment from "moment";
import Icon from "@mui/material/Icon";
import MKInput from "../../components/MKInput";
import {Field} from "formik";
import MKButton from "../../components/MKButton";
import axios from "axios";
import {API_URL, PUSHER_ID} from "../../config";
import {useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Pusher from "pusher-js";

const ConversationBox = ({name, updatedAt, lastMessage, selected, onClick}) => {
    return <MKBox sx={{cursor: 'pointer'}} py={1} px={2} mb={1} variant="gradient"
                  bgColor={selected ? "primary" : "white"} borderRadius={4} shadow={selected ? 'xs' : null}
                  onClick={onClick}>
        <MKTypography variant="h6" color={selected ? "white" : "black"} sx={{marginBottom: -1.5}}>{name}</MKTypography>
        <MKTypography variant="caption"
                      color={selected ? "white" : "black"}>{updatedAt !== 'No history yet' ? moment(updatedAt).fromNow() : updatedAt}</MKTypography>
        <MKTypography variant="body2" sx={{fontSize: 14, textOverflow: "ellipsis", maxHeight: 25, overflow: 'hidden'}}
                      color={selected ? "white" : "black"}>{lastMessage}</MKTypography>
    </MKBox>;
};

const ChatHeader = ({name, updatedAt}) => {
    return <MKBox bgColor="primary" shadow="sm" p={2} borderRadius={4} mt={-5}>
        <MKTypography variant="h6" color="white"
                      sx={{marginBottom: -1.5}}>{name}</MKTypography>
        <MKTypography variant="caption"
                      color="white">{updatedAt !== 'No history yet' ? moment(updatedAt).fromNow() : updatedAt}</MKTypography>
    </MKBox>
};

const Message = ({message, createdAt, isCurrentUser}) => {
    return <MKBox sx={{maxWidth: '60%'}} mb={2} sx={{alignSelf: isCurrentUser ? 'flex-end' : 'flex-start'}}>
        <MKBox p={1} px={2} borderRadius={8} bgColor={isCurrentUser ? 'primary' : 'white'} shadow="sm" sx={{display: 'inline-block'}}>
            <MKTypography sx={{display: 'block'}} color={isCurrentUser ? 'white' : 'black'} variant="button"
                          mb={-1}>{message}</MKTypography>
            <MKTypography variant="caption"
                          color={isCurrentUser ? 'white' : 'black'}><Icon>access_time</Icon> {moment(createdAt).format('MMM DD, HH:mm A')}
            </MKTypography>
        </MKBox>
    </MKBox>
};

const Chat = () => {

    const authState = useSelector((state) => state.authReducer);

    const audio = new Audio("/notification.mp3");

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [isInitLoading, setIsInitLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [conversations, setConversations] = useState([]);

    const [currentConversation, setCurrentConversation] = useState(null);

    const [conversationMessages, setConversationMessages] = useState([]);
    const [message, setMessage] = useState('');

    const messagesEndRef = React.useRef(null);

    useEffect(() => {
        const init = async () => {
            setIsInitLoading(true);
            await loadConversations();
            setIsInitLoading(false);
        };

        init();
    }, []);

    useEffect(() => {
        loadConversationMessages();
    }, [currentConversation]);

    useEffect(() => {

        if(currentConversation){

            const pusher = new Pusher(PUSHER_ID, {
                cluster: 'us2',
                authEndpoint: `${API_URL}/broadcasting/auth`,
                authorizer: (channel, options) => {
                    return {
                        authorize: (socketId, callback) => {
                            axios.post(`${API_URL}/broadcasting/auth`, {
                                socket_id: socketId,
                                channel_name: channel.name
                            })
                                .then(response => {
                                    callback(false, response.data);
                                })
                                .catch(error => {
                                    callback(true, error);
                                });
                        }
                    };
                },
            });

            const channel = pusher.subscribe(`private-reservations.${currentConversation.id}`);
            channel.bind('new-message', function(data) {

                setConversationMessages([...conversationMessages, data.message]);

                audio.play();

                messagesEndRef.current.scrollIntoView();
            });

            return () => {
                pusher.disconnect();
            }

        }

    }, [currentConversation, conversationMessages]);

    const loadConversations = async () => {

        try {

            const response = await axios.get(`${API_URL}/reservations`, {
                params: {
                    user_id: authState.userId,
                    sort: 'created_at',
                    direction: 'desc',
                }
            });

            setConversations(response.data);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading your conversations",
                icon: 'cancel',
                title: 'Chat',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const loadConversationMessages = async () => {

        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/messages/${currentConversation.id}`);

            setConversationMessages(response.data);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading your messages",
                icon: 'cancel',
                title: 'Chat',
                color: 'warning'
            });

            setShow(true);

        }

        setIsLoading(false);

    };

    const handleMessageSubmit = async () => {

        if(!message){
            return;
        }

        setIsSubmitting(true);

        try {
            const response = await axios.post(`${API_URL}/messages`, {
                user_id: authState.userId,
                reservation_id: currentConversation.id,
                message
            });

            setConversationMessages([...conversationMessages, response.data]);
            setMessage('');

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong sending the message",
                icon: 'cancel',
                title: 'Chat',
                color: 'warning'
            });

            setShow(true);

        }

        setIsSubmitting(false);
    };


    const renderConversations = () => {

        return conversations.map((conversation) => (
            <ConversationBox name={conversation.adventure.user.name} updatedAt={conversation.last_message ? conversation.last_message.created_at : 'No history yet'}
                             lastMessage={conversation.last_message ? conversation.last_message.message : ''}
                             onClick={() => setCurrentConversation(conversation)}
                             selected={currentConversation && conversation.id === currentConversation.id}/>
        ))
    };

    const renderConversationMessages = () => {
        return conversationMessages.map(message => (
            <Message message={message.message} createdAt={message.created_at}
                     isCurrentUser={authState.userId === message.user_id}/>
        ))
    };

    return <MKBox>
        <HeaderThree brandWhite={true} title="Chats" subtitle="Chat with your guides to solve any questions you may have"/>
        <Card style={{marginRight: 16, marginLeft: 16, marginTop: -40, zIndex: 0}}>
            <MKBox mt={4} mb={4} p={6}>
                {
                    isInitLoading ?
                        <MKBox mt={4} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <CircularProgress color="secondary"/>
                        </MKBox> :
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <MKTypography variant="h3">Chats</MKTypography>
                                <Card sx={{padding: 1, marginTop: 2}} raised={true}>
                                    {
                                        conversations.length > 0 ?
                                            renderConversations() :
                                            <MKTypography variant="body2" sx={{textAlign: 'center'}}>No active conversations,
                                                book an adventure to chat with your guide!</MKTypography>
                                    }
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                {
                                    currentConversation !== null ?
                                        <Card sx={{padding: 2}} raised={true}>
                                            <ChatHeader name={currentConversation.adventure.user.name}
                                                        updatedAt={currentConversation.last_message ? currentConversation.last_message.created_at : 'No history yet'}/>
                                            <MKBox mt={3} sx={{display: 'flex', flexDirection: 'column', minHeight: 300, maxHeight: 500, overflowY: 'scroll'}}>
                                                {
                                                    isLoading ?
                                                        <MKBox mt={4} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                            <CircularProgress color="secondary"/>
                                                        </MKBox> :
                                                        renderConversationMessages()
                                                }
                                                <div ref={messagesEndRef} />
                                            </MKBox>
                                            <MKBox sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                <MKInput name="message" value={message} onChange={(event) => setMessage(event.target.value)} variant="outlined" type="text" label="Type your message" fullWidth />
                                                <MKButton disabled={isSubmitting} onClick={handleMessageSubmit} iconOnly={true} color="secondary" sx={{paddingLeft: 4, paddingRight: 4, height: '100%', borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}><Icon>send</Icon></MKButton>
                                            </MKBox>
                                        </Card> :
                                        <Card sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%'
                                        }}>
                                            <MKTypography variant="body1" sx={{textAlign: 'center'}}>Click on a chat to view the
                                                conversation.</MKTypography>
                                        </Card>
                                }
                            </Grid>
                        </Grid>
                }
            </MKBox>
        </Card>
    </MKBox>
};

export default Chat;
