import MKBox from "../../components/MKBox";
import React, {Fragment} from "react";
import NavbarLight from "../../layouts/sections/navigation/navbars/components/NavbarLight";
import MKTypography from "../../components/MKTypography";
import MKButton from "../../components/MKButton";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {Wrapper} from "@googlemaps/react-wrapper";
import {APP_URL, MAPS_API_KEY} from "../../config";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import moment from "moment";
import MKSnackbar from "../../components/MKSnackbar";
import useReservationDetails from "./useReservationDetails";
import IconBox from "../../components/IconBox/IconBox";
import Rating from "../../components/Rating/Rating";
import ImageList from "../../components/ImageList/ImageList";
import MapView from "../../components/MapView/MapView";
import ReviewForm from "./ReviewForm/ReviewForm";
import reviewForm from "./schemas/review/form";
import reviewInitialValues from "./schemas/review/initialValues";
import reviewValidations from "./schemas/review/validations";
import {Form, Formik} from "formik";

const CheckItem = ({text}) => {
    return <MKBox sx={{display: 'flex', alignItems: 'center'}}>
        <Icon color="secondary">check_circle_outline</Icon>
        <MKTypography sx={{marginLeft: 1}} variant="body2" fontWeight="light">{text}</MKTypography>
    </MKBox>
};

const ReservationDetails = () => {

    const {
        snackbarConfig,
        toggleSnackbar,
        show,
        isInitLoading,
        reservation,
        adventure,
        handleReviewsClick,
        onReviewSubmit
    } = useReservationDetails();

    const {formId, formField} = reviewForm;
    const currentValidation = reviewValidations;


    const renderStatus = (status) => {
        return <h1>{status}</h1>;
    };

    const renderImages = () => {
        return adventure.images.map(image => `${APP_URL}/storage/photos/${image.name}`);
    };

    const renderPreys = () => {
        return adventure.preys.map(prey => (
            <Grid item md={4} xs={12}>
                <IconBox iconPosition="top" icon="pets" title={prey.name}
                         content={prey.description}/>
            </Grid>
        ));
    };

    const renderAmenities = () => {
        return adventure.amenities.map(amenity => (
            <MKBox mb={3}>
                <IconBox iconPosition="left" icon="check" title={amenity.title} content={amenity.description}/>
            </MKBox>
        ));
    };

    const renderLastReviews = () => {
        return adventure.last_reviews.map(review => <Grid item md={4} xs={12}>
                <Rating rating={review.score} color="#FFFFFF"/>
                <MKTypography color="white" variant="body2">"{review.content}"</MKTypography>
                <MKBox sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} mt={3}>
                    <MKBox>
                        <MKTypography color="white" variant="button" fontWeight="bold"
                                      sx={{display: 'block'}}>{review.user.name}</MKTypography>
                        <MKTypography color="white" variant="caption" sx={{display: 'block'}}><Icon
                            sx={{color: '#FFFFFF'}}>access_time</Icon> {moment(review.created_at).format('MMM DD, YYYY')}
                        </MKTypography>
                    </MKBox>
                </MKBox>
            </Grid>
        );
    };

    const renderItems = () => {
        return reservation.items.map(item => (
            <MKBox key={item.id} p={2} bgColor="white"
                   sx={{
                       display: 'flex',
                       flexDirection: 'row',
                       alignItems: 'center',
                       justifyContent: 'space-between',
                       borderStyle: 'solid',
                       borderWidth: 1,
                       borderColor: '#CECECE'
                   }}>
                <MKTypography mr={2} variant="h6" fontWeight="light">{item.pivot.amount}</MKTypography>
                <MKBox style={{flex: 1}}>
                    <MKTypography variant="h6">{item.pivot.description}</MKTypography>
                    <MKTypography variant="body2">{moment(reservation.due_at).format('MMM DD, YYYY')}</MKTypography>
                </MKBox>
                <MKTypography ml={2} variant="body2" fontWeight="bold"
                              color="secondary">${item.pivot.price} USD</MKTypography>
            </MKBox>
        ));
    };

    return <MKBox>
        <NavbarLight/>
        {
            isInitLoading ?
                <MKBox sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress color="secondary"/>
                </MKBox> :
                <MKBox>
                    <MKBox sx={{maxWidth: 1000}} mt={4} mb={12} mx="auto">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <MKTypography
                                    display="block"
                                    variant="button"
                                    color="primary"
                                    fontWeight="regular"
                                    mb={0.75}
                                >
                                    <Fragment>{adventure.city.name}, {adventure.city.state.name}&nbsp;&bull;&nbsp;</Fragment>
                                    <Fragment>{adventure.preys.length} preys</Fragment>
                                </MKTypography>
                                <MKTypography variant="h2">{adventure.name}</MKTypography>
                                <Rating rating={adventure.rating}/>
                                <MKBox mt={2}>
                                    <MKTypography variant="body2"
                                                  fontWeight={500}>{adventure.short_description}</MKTypography>
                                </MKBox>
                            </Grid>
                            <Grid item md={6} xs={12}>

                            </Grid>
                        </Grid>
                    </MKBox>
                    {
                        adventure.images.length > 0 &&
                        <ImageList
                            images={renderImages()}/>
                    }

                    <Card style={{marginRight: 16, marginLeft: 16, marginTop: -100, zIndex: 0}}>
                        <MKBox pt={24} pb={24} px={3} sx={{maxWidth: 1200, width: 1200}} mx="auto">
                            <Grid container spacing={3}>
                                {
                                    adventure.preys.length > 0 && renderPreys()
                                }
                            </Grid>
                            <MKBox mt={10}/>
                            <Grid container spacing={3}>
                                <Grid item md={6}>
                                    <MKTypography variant="h3">About the Trip</MKTypography>
                                    <MKTypography
                                        variant="body1">{adventure.city.name}, {adventure.city.state.name}</MKTypography>
                                    <MKBox mt={3} mb={5}>
                                        <MKTypography variant="body2">{adventure.description}</MKTypography>
                                    </MKBox>
                                    {
                                        adventure.amenities.length > 0 && renderAmenities()
                                    }
                                </Grid>
                                <Grid item md={6}>
                                    {
                                        (adventure.latitude && adventure.longitude) &&
                                            <MKBox sx={{position: 'sticky', top: 50}}>
                                                <Wrapper apiKey={MAPS_API_KEY} render={renderStatus}>
                                                    <MapView marker={true} center={{
                                                        lat: parseFloat(adventure.latitude),
                                                        lng: parseFloat(adventure.longitude)
                                                    }} zoom={15}/>
                                                </Wrapper>
                                            </MKBox>
                                    }
                                </Grid>
                            </Grid>
                            <MKBox mt={8}/>
                            {
                                adventure.last_reviews.length > 0 &&
                                <MKBox bgColor="primary" borderRadius={4} variant="gradient" p={6} mb={8}>
                                    <Grid container spacing={5}>
                                        <Grid item md={4} xs={12}>
                                            <MKTypography variant="h3" color="white">Recent Reviews</MKTypography>
                                            <MKTypography sx={{marginTop: 3}} variant="body2" color="white">Here’s what
                                                some
                                                guests
                                                have to say about the adventure.</MKTypography>
                                            <MKButton sx={{marginTop: 3}} variant="outlined" color="white"
                                                      onClick={handleReviewsClick}>VIEW
                                                ALL</MKButton>
                                        </Grid>
                                        {
                                            renderLastReviews()
                                        }
                                    </Grid>
                                </MKBox>
                            }
                            <MKBox>
                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={12}>
                                        <MKTypography variant="h3">Your Booking</MKTypography>
                                        <MKTypography variant="body2" fontWeight={500}>View your reservation
                                            details</MKTypography>
                                        <MKBox mt={3}>
                                            <MKTypography variant="h3">Payment Summary</MKTypography>
                                            <MKBox mt={2}>
                                                <CheckItem text={`Booking fee of $${reservation.payment} paid.`}/>
                                                <CheckItem
                                                    text={`Pay the remaining $${reservation.total - reservation.payment} upon arriving to your adventure.`}/>
                                            </MKBox>
                                        </MKBox>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        {
                                            renderItems()
                                        }
                                    </Grid>
                                </Grid>
                            </MKBox>
                            {
                                reservation && (moment(reservation.due_at).isBefore(moment())) &&
                                <MKBox my={5}>
                                    <MKTypography variant="h3">Rate Your Trip</MKTypography>
                                    <MKTypography variant="body2">How was your experience? Rate your adventure to help
                                        future adventurers!</MKTypography>
                                    <MKBox mt={3}>
                                        <Formik
                                            initialValues={reviewInitialValues}
                                            validationSchema={currentValidation}
                                            onSubmit={onReviewSubmit}
                                        >
                                            {({values, errors, touched, isSubmitting}) => (
                                                <Form id={formId} autoComplete="off">
                                                    <Grid container>
                                                        <Grid item md={9}>
                                                            <ReviewForm
                                                                formData={{values, touched, formField, errors}}/>
                                                        </Grid>
                                                        <Grid item md={3} sx={{paddingLeft: 4}}>
                                                            <MKButton
                                                                disabled={isSubmitting}
                                                                variant="gradient"
                                                                color="secondary"
                                                                type="submit"
                                                                fullWidth
                                                            >
                                                                {isSubmitting &&
                                                                <CircularProgress size={18} color="white"
                                                                                  sx={{marginRight: 1}}/>}
                                                                Submit
                                                            </MKButton>
                                                        </Grid>
                                                    </Grid>

                                                </Form>
                                            )}
                                        </Formik>
                                    </MKBox>
                                </MKBox>

                            }
                        </MKBox>
                    </Card>
                </MKBox>
        }
        <MKSnackbar
            color={snackbarConfig.color}
            icon={snackbarConfig.icon}
            title={snackbarConfig.title}
            content={snackbarConfig.message}
            dateTime=""
            autoHideDuration={3000}
            open={show}
            close={toggleSnackbar}
        />
    </MKBox>
};

export default ReservationDetails;
