import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../config";

const useWishlist = () => {

    const [isInitLoading, setIsInitLoading] = useState(true);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [adventures, setAdventures] = useState([]);

    useEffect(() => {
        const init = async () => {
            setIsInitLoading(true);
            await loadAdventures();
            setIsInitLoading(false);
        };

        init();
    }, []);

    const loadAdventures = async () => {

        try {


            const response = await axios.get(`${API_URL}/favorites`, {
                params: {
                    is_active: true,
                    sort: 'created_at',
                    direction: 'desc',
                }
            });

            setAdventures(response.data);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the adventures",
                icon: 'cancel',
                title: 'Adventures',
                color: 'warning'
            });

            setShow(true);

        }

    };


    return {
        isInitLoading,
        snackbarConfig,
        show,
        toggleSnackbar,
        adventures
    }
};

export default useWishlist;
