import React, {useEffect, useRef} from "react";
import secondary from "../../assets/theme/base/colors";

const MapView = ({marker = false, center, zoom}) => {
    const ref = useRef();

    useEffect(() => {
        const map = new window.google.maps.Map(ref.current, {
            center,
            zoom,
        });

        if(marker){
            const mark = new window.google.maps.Marker({
                map,
                position: center,
            });
        } else {
            const circle = new window.google.maps.Circle({
                strokeColor: secondary.main,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: secondary.main,
                fillOpacity: 0.35,
                map,
                center: center,
                radius: 5000,
            });
        }

    });


    return <div ref={ref} id="map" style={{width: '100%', height: '70vh'}}/>;
};

export default MapView;
