import HeaderThree from "../../layouts/sections/page-sections/page-headers/components/HeaderThree";
import MKBox from "../../components/MKBox";
import React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MKButton from "../../components/MKButton";
import AdventureCard from "../../components/AdventureCard/AdventureCard";
import MKPagination from "../../components/MKPagination";
import Icon from "@mui/material/Icon";
import {Form, Formik} from "formik";
import SearchForm from "./components/SearchForm/SearchForm";
import searchForm from "./schemas/search/form";
import filterForm from "./schemas/filter/form";
import searchValidations from "./schemas/search/validations";
import filterValidations from "./schemas/filter/validations";
import searchInitialValues from "./schemas/search/initialValues";
import filterInitialValues from "./schemas/filter/initialValues";
import CircularProgress from "@mui/material/CircularProgress";
import FilterForm from "./components/FilterForm/FilterForm";
import {APP_URL} from "../../config";
import MKSnackbar from "../../components/MKSnackbar";
import {Link} from "react-router-dom";
import useAdventure from "./useAdventures";
import MKTypography from "../../components/MKTypography";



const MainForm = ({onSubmit, cities, isLoading}) => {

    const {formId, formField} = searchForm;
    const currentValidation = searchValidations;

    return <Formik
        initialValues={searchInitialValues}
        validationSchema={currentValidation}
        onSubmit={onSubmit}
    >
        {({values, errors, touched, isSubmitting}) => (
            <Form id={formId} autoComplete="off">
                {
                    isLoading ?
                        <MKBox sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <CircularProgress color="secondary"/>
                        </MKBox> :
                        <Grid container spacing={3}>
                            <Grid item md={10}>
                                <SearchForm cities={cities} formData={{values, touched, formField, errors}}/>
                            </Grid>
                            <Grid item md={2}>
                                <MKButton
                                    disabled={isSubmitting || isLoading}
                                    variant="gradient"
                                    color="secondary"
                                    type="submit"
                                    fullWidth
                                >
                                    {isSubmitting &&
                                    <CircularProgress size={18} color="white"
                                                      sx={{marginRight: 1}}/>}
                                    Search
                                </MKButton>
                            </Grid>
                        </Grid>
                }
            </Form>
        )}
    </Formik>;
};

const FiltersBar = ({types, preys, onSubmit, defaultPrey = null}) => {

    const {formId, formField} = filterForm;
    const currentValidation = filterValidations;

    return <Formik
        initialValues={defaultPrey ? {...filterInitialValues, preys: [defaultPrey]} : filterInitialValues}
        validationSchema={currentValidation}
        onSubmit={onSubmit}
    >
        {({values, errors, touched, isSubmitting}) => (
            <Form id={formId} autoComplete="off">
                <MKBox>
                    <FilterForm typeData={types} preyData={preys} formData={{values, touched, formField, errors}}/>
                    <MKButton
                        disabled={isSubmitting}
                        variant="gradient"
                        color="secondary"
                        type="submit"
                        fullWidth
                    >
                        {isSubmitting &&
                        <CircularProgress size={18} color="white"
                                          sx={{marginRight: 1}}/>}
                        Apply Filters
                    </MKButton>
                </MKBox>
            </Form>
        )}
    </Formik>;

};

const Adventures = () => {

    const {
        isInitLoading,
        isLoading,
        snackbarConfig,
        show,
        toggleSnackbar,
        cities,
        preys,
        types,
        adventures,
        page,
        setPage,
        handleSubmit,
        state,
        filters
    } = useAdventure();

    const renderAdventures = () => {

        return adventures.map(adventure => {

            if(!adventure.is_approved){
                return;
            }

            return <Grid key={adventure.id} item md={6} sx={{marginTop: 2}}>
                <Link to={`/adventure-details/${adventure.id}`}>
                    <AdventureCard
                        image={adventure.images.length > 0 ? `${APP_URL}/storage/photos/${adventure.images[0].name}` : 'https://via.placeholder.com/150'}
                        title={adventure.name}
                        description={adventure.short_description}
                        meta={[`${adventure.city.name}, ${adventure.city.state.name}`, `${adventure.preys.length} Preys`]}
                        action={{
                            type: "internal",
                            route: "/somewhere",
                            color: "secondary",
                            label: "View More",
                        }}
                    />
                </Link>
            </Grid>;
        })
    };

    return <MKBox>
        <HeaderThree brandWhite={true} title="Book your next trip" subtitle="Discover amazing hunting and fishing experiences"/>
        <Card raised
              sx={{width: '100%', maxWidth: 1200, marginX: 'auto', marginTop: -6, zIndex: 1, paddingY: 3, paddingX: 4}}>
            <MainForm cities={cities} onSubmit={handleSubmit} isLoading={isInitLoading}/>
        </Card>
        {
            isInitLoading ?
                <MKBox mt={4} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress color="secondary"/>
                </MKBox> :
                <MKBox mt={8} sx={{maxWidth: 1200, marginX: 'auto'}}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={4}>
                            <FiltersBar types={types} preys={preys} onSubmit={handleSubmit} defaultPrey={state?.preyId}/>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {
                                isLoading ?
                                    <MKBox mt={4}
                                           sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <CircularProgress color="secondary"/>
                                    </MKBox> :
                                    adventures.length > 0 ?
                                        <Grid container spacing={4}>
                                            {renderAdventures()}
                                        </Grid> :
                                        <MKBox mt={4}
                                               sx={{
                                                   display: 'flex',
                                                   justifyContent: 'center',
                                                   alignItems: 'center',
                                                   width: '100%',
                                                   height: '100%'
                                               }}>
                                            <MKTypography variant="h6" sx={{textAlign: 'center'}}>No adventures found
                                                matching your query.</MKTypography>
                                        </MKBox>

                            }
                        </Grid>
                    </Grid>
                    <MKBox my={3}>
                        <MKPagination color="secondary">
                            <MKPagination item onClick={() => setPage(page - 1)} disabled={page === 1}>
                                <Icon>keyboard_arrow_left</Icon>
                            </MKPagination>
                            <MKPagination item active>{page}</MKPagination>
                            <MKPagination item onClick={() => setPage(page + 1)}>
                                <Icon>keyboard_arrow_right</Icon>
                            </MKPagination>
                        </MKPagination>
                    </MKBox>
                </MKBox>
        }
        <MKSnackbar
            color={snackbarConfig.color}
            icon={snackbarConfig.icon}
            title={snackbarConfig.title}
            content={snackbarConfig.message}
            dateTime=""
            autoHideDuration={3000}
            open={show}
            close={toggleSnackbar}
        />
    </MKBox>
};

export default Adventures;
