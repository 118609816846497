import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../config";
import {useParams, useNavigate} from 'react-router-dom';
import {useSelector} from "react-redux";

const useReservationDetails = () => {

    const {id} = useParams();

    const navigate = useNavigate();

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [isInitLoading, setIsInitLoading] = useState(true);

    const [reservation, setReservation] = useState(null);
    const [adventure, setAdventure] = useState(null);

    const [cartItems, setCartItems] = useState([]);

    const authState = useSelector((state) => state.authReducer);

    useEffect(() => {

        const init = async () => {
            setIsInitLoading(true);
            await loadReservation();
            setIsInitLoading(false);
        };

        init();

    }, []);

    const loadReservation = async () => {

        try {

            const response = await axios.get(`${API_URL}/reservations/${id}`);

            setReservation(response.data);
            setAdventure(response.data.adventure);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the reservation's details",
                icon: 'cancel',
                title: 'Reservations',
                color: 'warning'
            });

            setShow(true);

        }
    };

    const handleReviewsClick = () => {
        navigate(`/reviews/${adventure.id}`);
    };

    const onReviewSubmit = async (values, actions) => {

        try {

            await axios.post(`${API_URL}/reviews`, {
                adventure_id: adventure.id,
                user_id: authState.userId,
                ...values
            });

            actions.resetForm();

            setSnackbarConfig({
                message: "Thanks for your review!",
                icon: 'notifications',
                title: 'Reviews',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong submitting the review",
                icon: 'cancel',
                title: 'Reviews',
                color: 'warning'
            });

            setShow(true);

        }
    };

    return {
        snackbarConfig,
        toggleSnackbar,
        show,
        isInitLoading,
        adventure,
        reservation,
        cartItems,
        handleReviewsClick,
        onReviewSubmit
    }
};

export default useReservationDetails;
