import MKBox from "../MKBox";
import React from "react";


const ImageList = ({images}) => {

    return (
        <MKBox sx={{
            overflowX: 'auto',
            overflowY: 'visible',
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 8,
            paddingRight: 8,
            zIndex: 1,
            position: 'relative'
        }}>
            {images.map((image, index) => (
                <MKBox key={index} mr={3} bgColor="white" shadow="sm"
                       style={{width: 380, height: 250, borderRadius: 4}}>
                    <img src={image} style={{width: 380, height: 250, objectFit: 'cover', borderRadius: 4}}
                         alt={`Image ${index}`}/>
                </MKBox>
            ))}
        </MKBox>
    );
};

export default ImageList;
