import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../config";
import {useParams, useNavigate} from 'react-router-dom';

const useAdventureDetails = () => {

    const {id} = useParams();

    const navigate = useNavigate();

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [isInitLoading, setIsInitLoading] = useState(true);

    const [adventure, setAdventure] = useState(null);

    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {

        const init = async () => {
            setIsInitLoading(true);
            await loadAdventure();
            setIsInitLoading(false);
        };

        init();

    }, []);

    const loadAdventure = async () => {

        try {

            const response = await axios.get(`${API_URL}/adventures/${id}`);

            setAdventure(response.data);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the adventure's details",
                icon: 'cancel',
                title: 'Adventures',
                color: 'warning'
            });

            setShow(true);

        }
    };

    const handleItemAdd = (id, name, price) => {

        let newCart = [...cartItems];

        let index = newCart.findIndex(item => item.id === id);

        if (index >= 0)
            newCart[index] = {...newCart[index], amount: newCart[index].amount + 1};
        else {
            newCart.push({
                id,
                name,
                unit_price: price,
                amount: 1
            })
        }

        setCartItems(newCart);
    };

    const handleItemRemove = (id) => {

        let newCart = [...cartItems];

        let index = newCart.findIndex(item => item.id === id);

        if (index >= 0) {

            if (newCart[index].amount > 1)
                newCart[index] = {...newCart[index], amount: newCart[index].amount - 1};
            else
                newCart.splice(index, 1);

        }

        setCartItems(newCart);

    };

    const getCartItemAmount = (id) => {
        let item = cartItems.find((item) => item.id === id);

        if (item)
            return item.amount;

        return 0;
    };

    const handleSubmit = (values, cartItems) => {
        navigate('/checkout', {state: {...values, adventureId: id, cartItems: cartItems}});
    };

    const handleWishlist = async () => {

        try {
            await axios.post(`${API_URL}/favorites/toggle`, {
                adventure_id: adventure.id
            });

            setSnackbarConfig({
                message: "Added to your wishlist",
                icon: 'notifications',
                title: 'Wishlist',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {
            setSnackbarConfig({
                message: "Something went wrong adding to your wishlist",
                icon: 'cancel',
                title: 'Wishlist',
                color: 'warning'
            });

            setShow(true);
        }
    };

    const handleReviewsClick = () => {
        navigate(`/reviews/${id}`);
    };

    return {
        snackbarConfig,
        toggleSnackbar,
        show,
        isInitLoading,
        adventure,
        cartItems,
        handleItemAdd,
        handleItemRemove,
        getCartItemAmount,
        handleSubmit,
        handleWishlist,
        handleReviewsClick
    }
};

export default useAdventureDetails;
