/**
 =========================================================
 * Material Kit 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useEffect, useState} from "react";

// react-router components
import {Routes, Route, Navigate, useLocation} from "react-router-dom";

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";

// Material Kit 2 PRO React routes
import routes, {loggedRoutes} from "routes";
import Adventures from "./containers/adventures/Adventures";
import AdventureDetails from "./containers/adventure-details/AdventureDetails";
import Checkout from "./containers/checkout/Checkout";
import Chat from "./containers/chat/Chat";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {API_URL} from "./config";
import {signIn} from "./store/actions/auth";
import Loading from "./containers/loading";
import SignIn from "./containers/authentication/sign-in";
import SignUp from "./containers/authentication/sign-up";
import RecoverPassword from "./containers/authentication/recover-password";
import ResetPassword from "./containers/authentication/reset-password";
import ThankYou from "./containers/thank-you/ThankYou";
import Wishlist from "./containers/wishlist/Wishlist";
import Reviews from "./containers/reviews/Reviews";
import Reservations from "./containers/reservations/Reservations";
import ReservationDetails from "./containers/reservation-details/ReservationDetails";
import Preys from "./containers/preys/Preys";

export default function App() {
    const {pathname} = useLocation();

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const dispatch = useDispatch();

    const authState = useSelector((state) => state.authReducer);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        const checkUser = async () => {
            setIsLoading(true);

            try {
                const response = await axios.get(`${API_URL}/user`);

                dispatch(signIn(response.data.user.id, response.data.user.name, response.data.role.id, null));

            } catch (error) {

            }

            setIsLoading(false);
        };

        checkUser();

    }, []);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key}/>;
            }

            return null;
        });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            {
                isLoading ?
                    <Loading/> :
                    <>
                        {
                            authState.isLoggedIn ?
                                <Routes>
                                    {getRoutes(loggedRoutes)}
                                    <Route path="/adventures" element={<Adventures/>}/>
                                    <Route path="/adventure-details/:id" element={<AdventureDetails/>}/>
                                    <Route path="/checkout" element={<Checkout/>}/>
                                    <Route path="/chat" element={<Chat/>}/>
                                    <Route path="/thank-you" element={<ThankYou/>}/>
                                    <Route path="/wishlist" element={<Wishlist/>}/>
                                    <Route path="/preys" element={<Preys/>}/>
                                    <Route path="/reservations" element={<Reservations/>}/>
                                    <Route path="/reservation-details/:id" element={<ReservationDetails/>}/>
                                    <Route path="/reviews/:id" element={<Reviews/>}/>
                                    <Route path="*" element={<Navigate to="/adventures"/>}/>
                                </Routes> :
                                <Routes>
                                    <Route exact path="/auth/sign-in" element={<SignIn/>}
                                           key="sign-in"/>
                                    <Route exact path="/auth/sign-up" element={<SignUp/>}
                                           key="sign-up"/>
                                    <Route exact path="/auth/recover-password" element={<RecoverPassword/>}
                                           key="recover-password"/>
                                    <Route exact path="/auth/reset-password" element={<ResetPassword/>}
                                           key="reset-password"/>
                                    <Route path="/adventures" element={<Adventures/>}/>
                                    <Route path="/adventure-details/:id" element={<AdventureDetails/>}/>
                                    <Route path="/checkout" element={<Checkout/>}/>
                                    <Route path="/preys" element={<Preys/>}/>
                                    <Route path="/reviews/:id" element={<Reviews/>}/>
                                    <Route path="*" element={<Navigate to="/adventures"/>}/>
                                </Routes>
                        }
                    </>
            }
        </ThemeProvider>
    );
}
