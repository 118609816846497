import MKBox from "../../components/MKBox";
import React, {Fragment} from "react";
import NavbarLight from "../../layouts/sections/navigation/navbars/components/NavbarLight";
import MKTypography from "../../components/MKTypography";
import MKButton from "../../components/MKButton";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {Form, Formik} from "formik";
import bookingForm from "./schemas/booking/form";
import bookingValidations from "./schemas/booking/validations";
import bookingInitialValues from "./schemas/booking/initialValues";
import BookingForm from "./components/BookingForm/BookingForm";
import {Wrapper} from "@googlemaps/react-wrapper";
import {FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton} from "react-share";
import {API_URL, APP_URL, MAPS_API_KEY} from "../../config";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import moment from "moment";
import MKSnackbar from "../../components/MKSnackbar";
import useAdventureDetails from "./useAdventureDetails";
import IconBox from "../../components/IconBox/IconBox";
import CartItem from "./components/CartItem/CartItem";
import Rating from "../../components/Rating/Rating";
import ImageList from "../../components/ImageList/ImageList";
import MapView from "../../components/MapView/MapView";
import axios from "axios";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const Booking = ({cartItems, onSubmit}) => {

    const {formId, formField} = bookingForm;
    const currentValidation = bookingValidations;

    const renderCartItems = () => {
        return cartItems.map((item) => (
            <MKBox p={2} bgColor="white"
                   sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <MKTypography mr={1} variant="h6" fontWeight="light">{item.amount}</MKTypography>
                <MKTypography variant="h6" style={{flex: 1}}>{item.name}</MKTypography>
                <MKTypography ml={1} variant="body2" fontWeight="bold"
                              color="secondary">${(item.unit_price * item.amount).toFixed(2)} USD</MKTypography>
            </MKBox>
        ))
    };

    return <Formik
        initialValues={bookingInitialValues}
        validationSchema={currentValidation}
        onSubmit={(values, actions) => onSubmit(values, cartItems)}
    >
        {({values, errors, touched, isSubmitting}) => (
            <Form id={formId} autoComplete="off">
                <BookingForm formData={{values, touched, formField, errors}}/>
                <MKBox my={2}>
                    {
                        cartItems.length > 0 ?
                            renderCartItems() :
                            <MKBox p={3} bgColor="white">
                                <MKTypography variant="body2" style={{textAlign: 'center'}}>No items on
                                    cart.</MKTypography>
                            </MKBox>
                    }
                </MKBox>
                <MKButton variant="gradient" color="secondary" fullWidth
                          disabled={isSubmitting || cartItems.length === 0} type="submit">Proceed
                    to Checkout</MKButton>
            </Form>
        )}
    </Formik>;
};

const AdventureDetails = () => {

    const authState = useSelector((state) => state.authReducer);

    const navigate = useNavigate();

    const {
        snackbarConfig,
        toggleSnackbar,
        show,
        isInitLoading,
        adventure,
        cartItems,
        handleItemAdd,
        handleItemRemove,
        getCartItemAmount,
        handleSubmit,
        handleWishlist,
        handleReviewsClick
    } = useAdventureDetails();

    const renderStatus = (status) => {
        return <h1>{status}</h1>;
    };

    const renderImages = () => {
        return adventure.images.map(image => `${APP_URL}/storage/photos/${image.name}`);
    };

    const renderPreys = () => {
        return adventure.preys.map(prey => (
            <Grid item md={4} xs={12}>
                <IconBox iconPosition="top" icon={prey.icon} title={prey.name}
                         content={prey.description}/>
            </Grid>
        ));
    };

    const renderAmenities = () => {
        return adventure.amenities.map(amenity => (
            <MKBox mb={3}>
                <IconBox iconPosition="left" icon="check" title={amenity.title} content={amenity.description}/>
            </MKBox>
        ));
    };

    const renderItems = () => {
        return adventure.items.map(item => (
            <CartItem id={item.id} title={item.name} description={item.description}
                      price={item.price} amount={getCartItemAmount(item.id)}
                      onAdd={handleItemAdd} onRemove={handleItemRemove}/>
        ));
    };

    const renderLastReviews = () => {
        return adventure.last_reviews.map(review => <Grid item md={4} xs={12}>
                <Rating rating={review.score} color="#FFFFFF"/>
                <MKTypography color="white" variant="body2">"{review.content}"</MKTypography>
                <MKBox sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} mt={3}>
                    <MKBox>
                        <MKTypography color="white" variant="button" fontWeight="bold"
                                      sx={{display: 'block'}}>{review.user.name}</MKTypography>
                        <MKTypography color="white" variant="caption" sx={{display: 'block'}}><Icon
                            sx={{color: '#FFFFFF'}}>access_time</Icon> {moment(review.created_at).format('MMM DD, YYYY')}
                        </MKTypography>
                    </MKBox>
                </MKBox>
            </Grid>
        );
    };

    return <MKBox>
        <NavbarLight/>
        {
            isInitLoading ?
                <MKBox sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress color="secondary"/>
                </MKBox> :
                <MKBox>
                    <MKBox sx={{maxWidth: 1000}} mt={4} mb={12} mx="auto">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <MKTypography
                                    display="block"
                                    variant="button"
                                    color="primary"
                                    fontWeight="regular"
                                    mb={0.75}
                                >
                                    <Fragment>{adventure.city.name}, {adventure.city.state.name}&nbsp;&bull;&nbsp;</Fragment>
                                    <Fragment>{adventure.preys.length} preys</Fragment>
                                </MKTypography>
                                <MKTypography variant="h2">{adventure.name}</MKTypography>
                                <Rating rating={adventure.rating}/>
                                <MKBox mt={2}>
                                    <MKTypography variant="body2"
                                                  fontWeight={500}>{adventure.short_description}</MKTypography>
                                </MKBox>
                                <MKBox sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} mt={2}>
                                    <MKButton onClick={handleWishlist} variant="outlined" color="secondary"><Icon
                                        color="secondary"
                                        style={{marginRight: 4}}>favorite</Icon>ADD
                                        TO
                                        WISHLIST</MKButton>
                                    <MKTypography variant="body2" fontWeight="bold" ml={2}>Share</MKTypography>
                                    <MKBox mx={1}>
                                        <FacebookShareButton url={"google.com"}>
                                            <FacebookIcon size={24} iconFillColor="#ffffff" bgStyle={{fill: 'black'}}
                                                          round/>
                                        </FacebookShareButton>
                                    </MKBox>
                                    <WhatsappShareButton url={"google.com"}>
                                        <WhatsappIcon size={24} iconFillColor="#ffffff" bgStyle={{fill: 'black'}}
                                                      round/>
                                    </WhatsappShareButton>
                                </MKBox>
                            </Grid>
                            <Grid item md={6} xs={12}>

                            </Grid>
                        </Grid>
                    </MKBox>
                    {
                        adventure.images.length > 0 &&
                        <ImageList
                            images={renderImages()}/>
                    }

                    <Card style={{marginRight: 16, marginLeft: 16, marginTop: -100, zIndex: 0}}>
                        <MKBox pt={24} pb={24} px={3} sx={{maxWidth: 1200, width: 1200}} mx="auto">
                            <Grid container spacing={3}>
                                {
                                    adventure.preys.length > 0 && renderPreys()
                                }
                            </Grid>
                            <MKBox mt={10}/>
                            <Grid container spacing={3}>
                                <Grid item md={6}>
                                    <MKTypography variant="h3">About the Trip</MKTypography>
                                    <MKTypography
                                        variant="body1">{adventure.city.name}, {adventure.city.state.name}</MKTypography>
                                    <MKBox mt={3} mb={5}>
                                        <MKTypography variant="body2">{adventure.description}</MKTypography>
                                    </MKBox>
                                    {
                                        adventure.amenities.length > 0 && renderAmenities()
                                    }
                                </Grid>
                                <Grid item md={6}>
                                    {
                                        (adventure.latitude && adventure.longitude) &&
                                        <MKBox sx={{position: 'sticky', top: 50}}>
                                            <Wrapper apiKey={MAPS_API_KEY} render={renderStatus}>
                                                <MapView center={{
                                                    lat: parseFloat(adventure.latitude),
                                                    lng: parseFloat(adventure.longitude)
                                                }} zoom={10}/>
                                            </Wrapper>
                                        </MKBox>
                                    }
                                </Grid>
                            </Grid>
                            <MKBox mt={8}/>
                            {
                                adventure.last_reviews.length > 0 &&
                                <MKBox bgColor="primary" borderRadius={4} variant="gradient" p={6} mb={8}>
                                    <Grid container spacing={5}>
                                        <Grid item md={4} xs={12}>
                                            <MKTypography variant="h3" color="white">Recent Reviews</MKTypography>
                                            <MKTypography sx={{marginTop: 3}} variant="body2" color="white">Here’s what
                                                some
                                                guests
                                                have to say about the trip.</MKTypography>
                                            <MKButton sx={{marginTop: 3}} variant="outlined" color="white"
                                                      onClick={handleReviewsClick}>VIEW
                                                ALL</MKButton>
                                        </Grid>
                                        {
                                            renderLastReviews()
                                        }
                                    </Grid>
                                </MKBox>
                            }
                            <MKBox>
                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={12}>
                                        <MKTypography variant="h3">Book Your Trip</MKTypography>
                                        <MKTypography variant="body2" fontWeight={500}>View the available
                                            products {adventure.name} have to offer.</MKTypography>
                                        <MKBox mt={5}>
                                            {
                                                adventure.items.length > 0 &&
                                                renderItems()
                                            }
                                        </MKBox>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <MKBox p={4} px={4} bgColor="#F3EFF5" sx={{position: 'sticky', top: 50, borderRadius: 12}}>
                                            <MKTypography variant="h4" sx={{textAlign: 'center'}}>Your
                                                Order</MKTypography>
                                            {
                                                authState.isLoggedIn ?
                                                    <Booking cartItems={cartItems} onSubmit={handleSubmit}/> :
                                                    <MKBox mt={2} sx={{backgroundColor: '#FFFFFF'}} p={4}>
                                                        <MKTypography sx={{textAlign: 'center'}}>You must log in to book a trip</MKTypography>
                                                        <MKBox mt={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                                            <MKButton onClick={() => navigate('/auth/sign-in')} variant="contained" color="secondary" sx={{marginRight: 1}}>Sign In</MKButton>
                                                            <MKButton variant="outlined" color="secondary" sx={{marginLeft: 1}} onClick={() => navigate('/auth/sign-up')}>Sign Up</MKButton>
                                                        </MKBox>
                                                    </MKBox>
                                            }
                                        </MKBox>
                                    </Grid>
                                </Grid>
                            </MKBox>
                        </MKBox>
                    </Card>
                </MKBox>
        }
        <MKSnackbar
            color={snackbarConfig.color}
            icon={snackbarConfig.icon}
            title={snackbarConfig.title}
            content={snackbarConfig.message}
            dateTime=""
            autoHideDuration={3000}
            open={show}
            close={toggleSnackbar}
        />
    </MKBox>
};

export default AdventureDetails;
