/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { score, content },
} = checkout;

const validations = Yup.object().shape({
  [score.name]: Yup.number().required(score.errorMsg).positive(score.errorMsg),
  [content.name]: Yup.string().required(content.errorMsg),
});

export default validations;
