/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";

// Formik
import {ErrorMessage, Field} from "formik";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import MDDatePicker from "../MDDatePicker";
import Dropzone from "../Dropzone";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import MDAutocomplete from "../MDAutocomplete";
import React from "react";
import MKSlider from "../MKSlider";

function FormField({label, name, type, options, ...rest}) {
    return (
        <MKBox mb={1.5}>
            {
                type === 'date' &&
                <Field {...rest} name={name} options={options} component={MDDatePicker} input={{label: label, fullWidth: true}}/>
            }
            {
                (type === 'text' || type === 'number' || type === 'email' || type === 'password') &&
                <Field {...rest} name={name} variant="standard" type={type} label={label} as={MKInput} fullWidth/>
            }
            {
                type === 'checkbox' &&
                <Field
                    as={FormControlLabel}
                    type={type}
                    name={name}
                    label={label}
                    control={<Checkbox/>}
                    {...rest}
                />
            }
            {
                type === 'file' &&
                <>
                    <MKBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <MKTypography component="label" variant="button" fontWeight="regular" color="text">
                            {label}
                        </MKTypography>
                    </MKBox>
                    <Field {...rest} name={name} component={Dropzone} />
                </>
            }
            {
                type === 'select' &&
                <Field
                    {...rest}
                    name={name}
                    label={label}
                    options={options}
                    component={MDAutocomplete}
                />

            }
            {
                type === 'slider' &&
                <Field
                    {...rest}
                    name={name}
                    component={MKSlider}
                />

            }
            <MKBox mt={0.75}>
                <MKTypography component="div" variant="caption" color="error" fontWeight="regular">
                    <ErrorMessage name={name}/>
                </MKTypography>
            </MKBox>
        </MKBox>
    );
}

// typechecking props for FormField
FormField.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

export default FormField;
