/* eslint-disable no-param-reassign */
/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes

import brand from "assets/images/logo.png";
import brandW from "assets/images/logo_white.png";
import {useSelector} from "react-redux";
import {loggedRoutes, routes} from "../../../../../../routes";

function NavbarTransparent({brandWhite = false}) {

  const authState = useSelector((state) => state.authReducer);

  return (
    <DefaultNavbar
      routes={authState.isLoggedIn ? loggedRoutes : routes}
      transparent
      relative
      light
      brand={brandWhite ? brandW : brand}
    />
  );
}

export default NavbarTransparent;
