import HeaderThree from "../../layouts/sections/page-sections/page-headers/components/HeaderThree";
import MKBox from "../../components/MKBox";
import React from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import {APP_URL} from "../../config";
import MKSnackbar from "../../components/MKSnackbar";
import {Link} from "react-router-dom";
import useReservations from "./useReservations";
import ReservationCard from "../../components/ReservationCard/ReservationCard";

const Reservations = () => {

    const {
        isInitLoading,
        isLoading,
        snackbarConfig,
        show,
        toggleSnackbar,
        reservations,
    } = useReservations();

    const renderMyAdventures = () => {
        return reservations.map(reservation => <Grid key={reservation.id} item md={4} sx={{marginTop: 2}}>
            <Link to={`/reservation-details/${reservation.id}`}>
                <ReservationCard
                    image={reservation.adventure.images.length > 0 ? `${APP_URL}/storage/photos/${reservation.adventure.images[0].name}` : 'https://via.placeholder.com/150'}
                    title={reservation.adventure.name}
                    dueAt={reservation.due_at}
                    description={reservation.adventure.short_description}
                />
            </Link>
        </Grid>)
    };

    return <MKBox>
        <HeaderThree brandWhite={true} title="My Trips" subtitle="View your reservations"/>
        {
            isInitLoading ?
                <MKBox mt={4} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress color="secondary"/>
                </MKBox> :
                <MKBox mt={8} mb={8} sx={{maxWidth: 1200, marginX: 'auto'}}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12}>
                            {
                                isLoading ?
                                    <MKBox mt={4}
                                           sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <CircularProgress color="secondary"/>
                                    </MKBox> :
                                    <Grid container spacing={4}>
                                        {
                                            renderMyAdventures()
                                        }
                                    </Grid>
                            }
                        </Grid>
                    </Grid>
                </MKBox>
        }
        <MKSnackbar
            color={snackbarConfig.color}
            icon={snackbarConfig.icon}
            title={snackbarConfig.title}
            content={snackbarConfig.message}
            dateTime=""
            autoHideDuration={3000}
            open={show}
            close={toggleSnackbar}
        />
    </MKBox>
};

export default Reservations;
